<template>
  <!-- 角色管理 -->
  <div class="Authority">
    <Header title="角色管理" index="首页" titleOne="组织管理" beforeTitle="角色管理" />
    <div class="content">
      <div class="topUsers">
        <div>
          <el-select
            @change="search"
            clearable
            placeholder="请选择部门"
            v-model="roleParams.organization_id"
          >
            <el-option
              v-for="(item,index) in roleList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-input
            clearable
            v-model="roleParams.keywords"
            placeholder="请输入角色名称"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-button class="search" @click="handleSearch()">查询</el-button>
        </div>
        <el-button
          v-if="privilige_list.authority.includes('add')"
          plain
          @click="addrole()"
          class="add"
        >新增</el-button>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="roleData.data"
        style="width: 100%"
      >
        <el-table-column prop="name" label="角色/职位名称" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="organization_name" label="部门" :show-overflow-tooltip="true">
          <!-- <template
            slot-scope="scope"
          >{{roleList.find((item)=>item.id == scope.row.organization_id).name}}</template>-->
        </el-table-column>
        <el-table-column prop="status" label="状态" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span class="state enable" v-if="scope.row.status == 1">
              <i></i>启用
            </span>
            <span class="state disable" v-else>
              <i></i>禁用
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.create_time | fmtdate }}</template>
        </el-table-column>
        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="privilige_list.authority.includes('edit')"
              class="edit"
              size="mini"
              @click="editrole(scope.row.id)"
            >编辑</el-button>
            <el-button
              :disabled="scope.row.status != 1"
              class="edit"
              size="mini"
              @click="editoperate(scope.row.id)"
            >操作权限</el-button>
            <el-button
              :disabled="scope.row.status != 1"
              class="edit"
              size="mini"
              @click="editdata(scope.row.id)"
            >数据权限</el-button>
            <el-button
              v-if="privilige_list.authority.includes('delete')"
              class="del"
              size="mini"
              @click="handleDelete(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="roleParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="roleParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="roleData.total"
        ></el-pagination>
      </div>

      <el-dialog :title="titleMap[dialogStatus]" :visible.sync="dialogFormVisible" width="400px">
        <el-form :model="roleform" :label-position="labelPosition" :rules="roleRule" ref="roleRef">
          <el-form-item label="部门" :label-width="formLabelWidth">
            <el-select style="width:100%" v-model="roleform.organization_id" placeholder="请选择所属部门">
              <el-option
                v-for="(item,index) in roleList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="角色/职位名称" :label-width="formLabelWidth">
            <el-input v-model="roleform.role_name" autocomplete="off" placeholder="请输入角色/职位名称"></el-input>
          </el-form-item>
          <el-form-item label="状态" :label-width="formLabelWidth">
            <el-select style="width:100%" v-model="roleform.status" placeholder="请选择状态">
              <el-option label="启用" :value="1">启用</el-option>
              <el-option label="禁用" :value="2">禁用</el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" :loading="btnLoading" @click="saverole()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  data () {
    return {
      btnLoading: false,//提交按钮动画
      roleData: [],//列表
      roleList: [],//部门选择
      value_class: "",
      loading: true,
      labelPosition: "top",
      dialogFormVisible: false,
      roleTotal: 0,
      roleParams: {
        keywords: '',
        token: '',
        page: 1,
        limit: 10,
        order: "id desc",
        organization_id: "",
      },
      roleform: {
        role_name: "",
        status: "",
        organization_id: '',
      },
      roleRule: {
        role_name: [
          {
            required: true,
            message: "请输入部门名称",
            trigger: "blur",
          },
        ],
      },
      titleMap: {
        addrole: "新增角色/职位",
        editrole: "编辑角色/职位",
      },
      dialogStatus: "",
      formLabelWidth: "80px",
      privilige_list: [],//权限展示列表
    };
  },
  activated () {
    this.roleParams
  },
  created () {
    this.roleParams.token = localStorage.getItem('token')
    this.getRoleList()
    this.getRole()
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
  },
  methods: {
    search () {
      this.axios.get('/api/role/list', { params: { ...this.roleParams } }).then((res) => {
        this.roleData = res
      })
    },
    getRoleList () {
      this.axios.get('/api/role/list', { params: { ...this.roleParams } }).then((res) => {
        this.roleData = res
      })
    },
    getRole () {
      this.axios.get('/api/organization/select_organization', { params: { token: this.roleParams.token } }).then((res) => {
        this.roleList = res.data
      })
    },
    handleSearch () {
      this.axios.get('/api/role/list', { params: { ...this.roleParams } }).then((res) => {
        this.roleData = res
      })
    },
    handleSizeChange (val) {
      this.roleParams.limit = val;
      this.axios.get('/api/role/list', { params: { ...this.roleParams } }).then((res) => {
        this.roleData = res
      })
    },
    handleCurrentChange (val) {
      this.roleParams.page = val;
      this.axios.get('/api/role/list', { params: { ...this.roleParams } }).then((res) => {
        this.roleData = res
      })
    },
    editrole (id) {
      this.allid = id;
      this.dialogFormVisible = true;
      this.dialogStatus = "editrole";
      this.axios
        .get("/api/role/view", {
          params: {
            role_id: id,
          },
        })
        .then((res) => {
          this.roleform.role_name = res.data.name;
          this.roleform.status = res.data.status;
          this.roleform.organization_id = res.data.organization_id;
        });
    },
    addrole () {
      this.dialogFormVisible = true;
      this.dialogStatus = "addrole";
      this.roleform.role_name = "";
      this.roleform.status = '';
      this.roleform.organization_id = '';
    },
    editdata (id) {
      this.$router.push("/authority/data?id=" + id);
    },
    editoperate (id) {
      this.$router.push("/authority/Operation?id=" + id);
    },
    saverole () {
      console.log(this.roleParams)
      if (!this.allid) {
        this.$refs.roleRef.validate((valid) => {
          if (valid) {
            this.axios
              .post("/api/role/store", this.roleform)
              .then((res) => {
                this.$message.success("添加成功");
                this.getRoleList()
              })
              .catch((res) => {
                console.log("err:", res);
              });
            this.dialogFormVisible = false;
          } else {
            console.log("输入错误");
          }
        });
      } else {
        this.roleform.role_id = this.allid;
        this.$refs.roleRef.validate((valid) => {
          if (valid) {
            this.axios
              .post("/api/role/store", this.roleform)
              .then((res) => {
                console.log(res);
                console.log(this.allid);
                this.$message.success("编辑成功");
                this.getRoleList()
              })
              .catch((res) => {
                console.log("err:", res);
              });
            this.dialogFormVisible = false;
          } else {
            console.log("输入错误");
          }
        });
      }
    },
    handleDelete (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios.delete("/api/role/delete", {
            params: {
              role_id: id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getRoleList()
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  beforeUpdate () {
    this.loading = false;
  },
};
</script>

<style scoped="scoped">
::v-deep .el-form-item__label {
  padding: 0 !important;
}

::v-deep .el-form-item {
  margin-bottom: 10px;
}

::v-deep .el-dialog__body {
  padding: 0 20px;
}
div/deep/.el-dialog__title {
  font-weight: 700;
}
.disable {
  color: #ff0f0f;
  font-weight: 700;
}
.disable i {
  background: #ff0f0f;
}
</style>
